<template>
  <div class="VueCarousel-navigation clearfix">
    <button
      type="button"
      aria-label="Previous page"
      role="button"
      class="VueCarousel-navigation-btn VueCarousel-navigation-prev"
      v-on:click.prevent="triggerPageAdvance('backward')"
      v-bind:class="{ 'VueCarousel-navigation--disabled': !canAdvanceBackward }"
      v-bind:style="`padding: ${clickTargetSize}px; margin-right: -${clickTargetSize}px;`"
      v-html="prevLabel"></button>
    <button
      type="button"
      aria-label="Next page"
      role="button"
      class="VueCarousel-navigation-btn VueCarousel-navigation-next"
      v-on:click.prevent="triggerPageAdvance()"
      v-bind:class="{ 'VueCarousel-navigation--disabled': !canAdvanceForward }"
      v-bind:style="`padding: ${clickTargetSize}px; margin-left: -${clickTargetSize}px;`"
      v-html="nextLabel"></button>
  </div>
</template>

<script>
export default {
  name: "navigation",
  data() {
    return {
      /**
       * link on Carousel
       */
      parentContainer: this.$parent,
      parentHeight: 100
    };
  },
  props: {
    /**
     * Amount of padding to apply around the label in pixels
     */
    clickTargetSize: {
      type: Number,
      default: 8
    },
    /**
     * Text content of the navigation next button
     */
    nextLabel: {
      type: String,
      default: "▶"
    },
    /**
     * Text content of the navigation prev button
     */
    prevLabel: {
      type: String,
      default: "◀"
    }
  },
  computed: {
    /**
     * @return {Boolean} Can the slider move forward?
     */
    canAdvanceForward() {
      return this.parentContainer.canAdvanceForward || false;
    },
    /**
     * @return {Boolean} Can the slider move backward?
     */
    canAdvanceBackward() {
      return this.parentContainer.canAdvanceBackward || false;
    }
  },
  methods: {
    /**
     * Trigger page change on +/- 1 depending on the direction
     * @param {"backward"} [direction]
     * @return {void}
     */
    triggerPageAdvance(direction) {
      /**
       * @event paginationclick
       * @type {string}
       */
      this.$emit("navigationclick", direction);
    }
  }
};
</script>

<style scoped lang="scss">
.VueCarousel-navigation {
  height: 100%;
  top: 0 !important;
  left: 0 !important;
  right: 0 !important;
  transform: none !important;
  pointer-events: none;
}

.VueCarousel-navigation-btn {
  box-sizing: border-box;
  color: #000;
  text-decoration: none;
  appearance: none;
  border: none;
  background-color: transparent;
  padding: 0;
  cursor: pointer;
  outline: none;
  position: relative;
  pointer-events: auto;
}

.VueCarousel-navigation-next {
  float: right;
  height: 100%;
  width: 7vw;
  .carousel-icons {
    margin-left: -1rem;
  }
}

.VueCarousel-navigation-prev {
  float: left;
  height: 100%;
  width: 7vw;
}

.VueCarousel-navigation--disabled {
  opacity: 0.5;
  cursor: default;
}
</style>
