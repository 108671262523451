<template>
  <div v-show="parentContainer.pageCount > 1" class="VueCarousel-pagination">
    <ul class="VueCarousel-dot-container" role="tablist">
      <li
        class="VueCarousel-dot"
        aria-hidden="false"
        role="presentation"
        :label="parentContainer.dotsLabels[index]"
        :aria-selected="isCurrentDot(index) ? 'true' : 'false'"
        v-bind:class="{ 'VueCarousel-dot--active': isCurrentDot(index) }"
        v-for="(page, index) in parentContainer.pageCount"
        :key="index"
        v-on:click="goToPage(index)"
        :style="`
          margin-top: ${parentContainer.paginationPadding * 2}px;
          padding: ${parentContainer.paginationPadding}px;
        `"
      >
        <button
          type="button"
          role="button"
          class="VueCarousel-dot-button"
          :tabindex="index"
          :style="`
            width: ${parentContainer.paginationSize}px;
            height: ${parentContainer.paginationSize}px;
            background: ${isCurrentDot(index) ? parentContainer.paginationActiveColor : parentContainer.paginationColor};
          `"
        ></button>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: "pagination",
  data() {
    return {
      /**
       * link on Carousel
       */
      parentContainer: this.$parent
    };
  },
  methods: {
    /**
     * Change page by index
     * @param {number} index
     * return {void}
     */
    goToPage(index) {
      /**
       * @event paginationclick
       * @type {number}
       */
      this.$emit("paginationclick", index);
    },

    /**
     * Check on current dot
     * @param {number} index - dot index
     * @return {boolean}
     */
    isCurrentDot(index) {
      return index === this.parentContainer.currentPage;
    }
  }
}
</script>

<style scoped>
.VueCarousel-pagination {
  width: 100%;
  text-align: center;
}

.VueCarousel-dot-container {
  display: inline-block;
  margin: 0 auto;
  padding: 0;
}

.VueCarousel-dot {
  display: inline-block;
  cursor: pointer;
}

.VueCarousel-dot-button {
  appearance: none;
  border: none;
  background-color: transparent;
  padding: 0;
  border-radius: 100%;
  outline: none;
  cursor: pointer;
}

.VueCarousel-dot-button:focus {
  outline: 1px solid lightblue;
}
</style>
